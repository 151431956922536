import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';
import UserSubmenu from '../../components/UserSubmenu';

import Config from '../../utils/Config';
import { calculatePhoneAuthBypassChecksum } from '../../utils/Helpers';
import { ROLES } from '../../utils/Constants';

export default function View() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);

  const { appContext, setDialogBox } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

    console.log(queryParameters.get('id'))

    console.log(data.length)
    console.log(data)
  
    const deleteUser = async (id) => {
      if (window.confirm('Obrisati korisnika?')) {
        setDialogBox({show: true, type: 'loadingBox'});
  
        try {
          const response = await axios.get(Config.BASE_URL + '/admin/users/delete?userId=' + id, {
            headers: {
              Authorization: 'Bearer ' + appContext.userToken, 
              'Cache-Control': 'no-cache',
            },
          });
        } catch (error) {
          console.error('Error:', error);
          ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
        }
        
        setDialogBox({show: false});

        navigate('/users/list');
      }
    }
  
    if (data === false) {
      return (<Loading />);
    }

    if (!data) {
      return (
        <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
          <Typography variant="h4">Nema podataka za datog korisnika</Typography>
        </Container>
      )
    }
  
    return (
      <>
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Typography variant="h4">{data.firstName} {data.lastName}</Typography>
        </Grid>
        <Divider light />
        <UserSubmenu user={data} />
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Telefon:</Grid>
          <Grid item xs={3} textAlign="left">{data.phone}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold'}}>Email:</Grid>
          <Grid item xs={3} textAlign="left">{data.email}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Kompanija:</Grid>
          <Grid item xs={3} textAlign="left">{data.client.name}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Grad:</Grid>
          <Grid item xs={3} textAlign="left">{data.city}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }}>
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Odabrano vozilo:</Grid>
          <Grid item xs={3} textAlign="left">{data.selectedCar?.garageNumber} - {data.selectedCar?.registrationPlate} - {data.selectedCar?.brand.name} {data.selectedCar?.model}</Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 1 }} className="FormRowOdd">
          <Grid item xs={2} textAlign="left" sx={{ fontWeight: 'bold' }}>Kod za autorizaciju telefona:</Grid>
          <Grid item xs={3} textAlign="left">{calculatePhoneAuthBypassChecksum(data.phone)}</Grid>
        </Grid>
      </Container>
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
        <Button variant="contained" color="success" sx={{mx: 1}} onClick={() => navigate('/users/edit?id=' + queryParameters.get('id'))}>Izmeni</Button>
        {(appContext.userData.role.roleId == ROLES.SUPERADMINISTRATOR || appContext.userData.role.roleId == ROLES.ADMINISTRATOR) && (
        <Button variant="contained" color="error" sx={{mx: 1}} onClick={(e) => { deleteUser(queryParameters.get('id'))}}>Obriši</Button>
        )}
        <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/users/list')}>Nazad</Button>
      </Container>
      </>
    );
}