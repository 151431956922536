import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, TextField } from '@mui/material';
import {FormErrorProvider, FormContainer, TextFieldElement, SelectElement, TextareaAutosizeElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

export default function Edit() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/collaborators/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log("handleFormSubmit")
    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/collaborators/edit', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);

      navigate('/collaborators/list');
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  console.log(queryParameters.get('id'))

  console.log(data.length)
  console.log(data)

  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka za datog saradnika</Typography>
      </Container>
    )
  }

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Izmena saradnika {data.name}</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          defaultValues={data}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Naziv: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="name" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Poslovno ime:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="fullName" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Tip: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="type"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Agencija',
                  label: 'Agencija'
                },
                {
                  id: 'Tehnički pregled',
                  label: 'Tehnički pregled'
                },
                {
                  id: 'Zakupac',
                  label: 'Zakupac'
                },
                {
                  id: 'AO osiguranje',
                  label: 'AO osiguranje'
                },
                {
                  id: 'Kasko osiguranje',
                  label: 'Kasko osiguranje'
                },
                {
                  id: 'Pomoć na putu',
                  label: 'Pomoć na putu'
                },
                {
                  id: 'Servis',
                  label: 'Servis'
                },
                {
                  id: 'Zamena pneumatika',
                  label: 'Zamena pneumatika'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Matični broj:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="idNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>JMBG:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="jmbgNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>PIB:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="taxNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Pravni oblik:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="legalForm"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Nedefinisano',
                  label: 'Nedefinisano',
                  
                },
                {
                  id: 'D.O.O.',
                  label: 'D.O.O.'
                },
                {
                  id: 'Preduzetnik',
                  label: 'Preduzetnik'
                },
                {
                  id: 'Fizičko lice',
                  label: 'Fizičko lice'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Status poreskog obveznika:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="taxSystem"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Nedefinisano',
                  label: 'Nedefinisano',
                  
                },
                {
                  id: 'Aktivan',
                  label: 'Aktivan'
                },
                {
                  id: 'Nije u sistemu',
                  label: 'Nije u sistemu'
                },
                {
                  id: 'Odregistrovan',
                  label: 'Odregistrovan'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Kontakt osoba:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="contactPerson" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Adresa:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="address" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Grad:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="city" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Poštanski broj:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="zipCode" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Zemlja:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="country" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>E-mail:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="email" size="small" type="email" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Fiksni telefon 1:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="phoneNumber1" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Mobilni telefon 1:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="mobileNumber1" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Pomoć na putu u Srbiji - broj telefona:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="roadAssistanceDomestic" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Pomoć na putu u inostranstvu - broj telefona:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="roadAssistanceForeign" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Radno vreme:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="workingHours" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena:</Grid>
            <Grid item xs={3} textAlign="left"><TextareaAutosizeElement name="note" rows="5" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Latituda:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="latitude" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Longituda:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="longitude" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Izmeni</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/collaborators/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}